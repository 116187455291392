import 'flexslider/jquery.flexslider';
import 'bpopup/jquery.bpopup';
import 'beefup';
import 'skeletabs/dist/skeletabs';
// import './fileUploader';
import './fileUploader_2nd';
import 'jquery-hashchange/jquery.ba-hashchange';
import './jquery.easytabs.min';
import  'hc-sticky/dist/hc-sticky';




$(function() {



 




  // $('.featured_add_deal').bxSlider({
  //   pager: false,
  //   auto: true,
  //   controls: false,

  // });

  $('.featured_add_deal').flexslider({
    animation: "slide",
    namespace :'saruwata_featured_',
    smoothHeight:true,
    controlNav: false,
    // directionNav:false,
    touch:true,
    customDirectionNav: $(".custom_featured_slider_ctr a") ,
  });



  $('.homePageSlider').flexslider({
    animation: "fade",
    // smoothHeight : true,
    pauseOnHover: true,
    controlNav: false,
  });


  // $('.pop_sell_item').on('click', function(e) {

  //   $('.category_popup').bPopup({
  //     transition: false,
  //     follow: [true, false],
  //   });
  // });


  $('#p_carousel').flexslider({
    animation: "slide",
    controlNav: false,
    animationLoop: false,
    slideshow: false,
    itemWidth: 100,
    itemMargin: 10,
    asNavFor: '#p_slider'
  });

  $('#p_slider').flexslider({
    animation: "slide",
    controlNav: false,
    animationLoop: false,
    slideshow: false,
    sync: "#p_carousel",
    // smoothHeight:true
  });

  $('.ad_form_inner').beefup({
    openSingle: true,
    // trigger:'.section_title',
    // content: '.section_body'
  });



  $('.payment_tab_container').skeletabs({}, {
    tabGroup: 'payment_tab_inner',
    tabItem: 'payment_tab',
    tab: 'pay_tab',
    panelGroup: 'payment_tab_panel_wrap',
    panel: 'payment_tab_panel',
    active: 'active',
    disabled: 'disabled',
    init: 'init'

  });


  $('#ad_post_slider_thumb').flexslider({
    animation: "slide",
    controlNav: false,
    animationLoop: false,
    slideshow: false,
    itemWidth: 100,
    itemMargin: 10,
    asNavFor: '#bigPostImageWrapper',
    customDirectionNav: $(".custom-navigation_2 a"),
  });

  $('#bigPostImageWrapper').flexslider({
    animation: "slide",
    controlNav: false,
    animationLoop: false,
    slideshow: false,
    sync: "#ad_post_slider_thumb",
    customDirectionNav: $(".custom-navigation a"),
  });

  $('#similar_post_slider').flexslider({
    animation: "slide",
    controlNav: true,
    animationLoop: false,
    slideshow: false,
  });




// Faq page js

$('.faq_support_content_inner').easytabs({
  // panelClass:$('.tab'),
  panelContext:$('.faq_support_contentWrapper'),
  tabs:'ul.faq_menu > li',
  // defaultTab:'li:first-child',
  // tabActiveClass: 'active',
  transitionInEasing:'linear',
  transitionOutEasing:'linear',
});

$('.tab_container').beefup({
	openSingle:true,
});








});

document.addEventListener('DOMContentLoaded', function () {
  
  var offcanvasMenu = function(b,t,n) {
    var navOpenBtn = document.querySelector(b);
    var navCloseBtn = document.querySelector(t);
    var nav = document.querySelector(n);
    var pageContent = document.querySelector('.mask');
    var navList = document.querySelector('.nav__list');
    var page = document.querySelector('body');

    //open nav

    if(navOpenBtn){
      navOpenBtn.addEventListener('click', function(e) {
        e.preventDefault();
        navOpenBtn.classList.add('js-hidden');
        nav.classList.add('js-opened');
        page.style.overflow = 'hidden';
        pageContent.classList.add('js-opened');
      });
    }
    

    //close nav
    if(navCloseBtn){
      navCloseBtn.addEventListener('click', function(e) {
        e.preventDefault();
        navOpenBtn.classList.remove('js-hidden');
        nav.classList.remove('js-opened');
        page.style.overflow = 'initial';
        pageContent.classList.remove('js-opened');
      });
    }
    

    //closing navigation if click outside it

    if(pageContent){
      pageContent.addEventListener('click', function(e) {

        var evTarget = e.target;
        if ((evTarget !== nav) && (nav.classList.contains('js-opened')) && (evTarget !== navOpenBtn) && (evTarget.parentNode !== navOpenBtn)) {
          // console.log(navOpenBtn.firstChild);
          navOpenBtn.classList.remove('js-hidden');
          nav.classList.remove('js-opened');
          page.style.overflow = 'initial';
          pageContent.classList.remove('js-opened');
        }
      });
    }
    

  };

  // Filter Offcanvas
  new offcanvasMenu('.filter_offcanvas','.filter__close', '.filter_nav');
  new offcanvasMenu('.category_offcanvas','.cat__close', '.category_nav');
  new offcanvasMenu('.search_offcanvas','.search__close', '.search_nav');
  new offcanvasMenu('.profile_offcanvas','.profile__close', '.profile_nav');
  new offcanvasMenu('.location_offcanvas','.location__close', '.location_nav');


  var Sticky = new hcSticky('#right_ad_space', {
    stickTo: '#right_ad_container'
  });



});





export default {}